import axios from 'axios';
import { toast } from 'react-toastify';
import { getEnvironment } from '../utils/environment';

const ENV = getEnvironment();

const SettingsService = {
    getSettings: async () => {
        return await axios.get(`./react/properties/app-${ENV}.json`)
            .then((resp) => {
                return resp.data;
            })
            .catch((error) => {
                toast.error(`Unable to load settings:\n${error}`);
            }
        );
    }
}

const UserService = {
    getInfo: async () => {
        return await axios.get('/userDetails')
            .then((resp) => resp.data
        )
        .catch((error) => {
            //toast.error(`Unable to load user information:\n${error}`);
            console.log("Unable to load user information");
        })
    }
}

export default {
    SettingsService,
    UserService
}